import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = `https://thefoxpayadminpanelbe-py-a5cngna0awbng0bz.westeurope-01.azurewebsites.net/`;


export const getUsers = async (tenantId?: string): Promise<any> => {
    console.log('users', API_URL)
    try {
        const response = await axios.get<any>(`${API_URL}/users`, {
            params: { tenantId: tenantId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching uses:', error);
        throw error;
    }
};

// Fetch user info
export const getUserInfo = async () => {
    console.log('users2', API_URL)

    try {
        const response = await axios.get(`${API_URL}/get-user-info`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching user info", error);
        throw error;
    }
};

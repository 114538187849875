import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserInfo, getUsers } from "../services/usersService";

import { ROLES } from "../config/constants";
import { useSessionContext } from "supertokens-auth-react/recipe/session"

// Create a Context for the data
const DataContext = createContext();

export const DataProvider = React.memo(({ children }) => {
  const session = useSessionContext();
  const [users, setUsers] = useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo")) || null
  );
  const [loading, setLoading] = useState(!userInfo);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!userInfo && session) {
        try {
          const allUsers = await getUsers("public");
          const usersData = await getUserInfo();
          sessionStorage.setItem("userInfo", JSON.stringify(usersData));
          setUserInfo(usersData);
          setUsers(allUsers.flat());
        } catch (err) {
          setError("Failed to fetch data.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [session, userInfo]);

  const isSuperAdmin = userInfo?.rolesPerTenant[0]?.roles.some(
    (role) => role.role === ROLES.SUPERADMIN
  );

  return (
    <DataContext.Provider value={{ users, userInfo, isSuperAdmin, loading, error }}>
      {children}
    </DataContext.Provider>
  );
});

// Custom hook to use the data context
export const useData = () => useContext(DataContext);

import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:3001`;

    console.log(apiUrl)
    return apiUrl;
}

export function getWebsiteDomain() {
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:3000`;
    // const websiteUrl = `https://www.digitalfox.app`;


    console.log(websiteUrl)
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "The Fox Pay",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain()
    },
    recipeList: [
        EmailPassword.init(),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};

export const PreBuiltUIList = [EmailPasswordPreBuiltUI];
